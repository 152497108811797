import React from 'react';
import './Endowment_Plan_Info.css';
import Endowment_Plan_Img from '../../assets/images/Endowment_Plan.jpg';
import Saving_Practice from '../../assets/images/Saving_Practice.png';
import Longer_Term from '../../assets/images/Longer_Term.png';
import Tax_Benefits from '../../assets/images/Tax_Benefits.png';
import Future_Goals from '../../assets/images/Future_Goals.png';
import death_Benefits from '../../assets/images/Death_Benefits.png';
import Guaranteed_Returns from '../../assets/images/Guaranteed_Returns.png';
import Pension_Fund from '../../assets/images/Pension_Fund.png';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';

function Endowment_Plan_Info() {
    return (
        <>
            <Navbar />
            <div className='term-insurance-container'>
                <h1 className='term-heading'>Endowment Insurance</h1>
                <div className='term-content'>

                    {/* Image Section */}
                    <div className='term-image-container'>
                        <img
                            src={Endowment_Plan_Img}
                            alt='Term Insurance'
                            className='term-image'
                        />
                    </div>

                    {/* Text Section */}
                    <div className='term-text-container'>
                        <h3 className='term-subheading'>What are Endowment Insurance Plans?</h3>
                        <p className='term-description'>
                            Endowment insurance plans are a type of life insurance that provides both protection and savings benefits.
                            These plans offer policyholders a combination of insurance coverage and a savings component.
                            The policyholder pays regular premiums for a specified period, typically 10, 15, or 20 years, and upon the maturity of the policy or the death of the insured, a lump sum amount is paid out.<br />
                            Unlike traditional life insurance, endowment plans guarantee a payout regardless of whether the insured survives the policy term.
                            If the policyholder outlives the policy term, they receive the maturity benefit, which is the sum assured along with accumulated bonuses or returns.
                            If the insured passes away during the policy term, the death benefit is paid to the nominee or beneficiary. <br />
                            Endowment insurance plans serve as a means of long-term savings while providing financial protection to the insured and their loved ones.
                            They can be used to achieve specific financial goals such as funding education, buying a house, or retirement planning.
                            However, it’s important to note that these plans typically offer lower returns compared to pure investment products, as a portion of the premium goes towards providing the insurance coverage.
                        </p>
                        <button className='learn-more-btn'>Learn More</button>
                    </div>
                </div>

                <div class="term-benefits-container">
                    <h2 class="term-benefits-title">Benefits of Endowment Plans</h2>
                    <div class="term-benefits-row">
                        <div class="term-benefits-item">
                            <img src={Saving_Practice} class="term-benefits-img" alt="Saving Practice" />
                            <h3 class="term-benefits-heading">Saving Practice</h3>
                        </div>
                        <div class="term-benefits-item">
                            <img src={Longer_Term} class="term-benefits-img" alt="Longer Term" />
                            <h3 class="term-benefits-heading">Longer Term</h3>
                        </div>
                        <div class="term-benefits-item">
                            <img src={Tax_Benefits} class="term-benefits-img" alt="Tax Benefits" />
                            <h3 class="term-benefits-heading">Tax Benefits</h3>
                        </div>
                        <div class="term-benefits-item">
                            <img src={Future_Goals} class="term-benefits-img" alt="Future Goals" />
                            <h3 class="term-benefits-heading">Future Goals</h3>
                        </div>
                    </div>
                    <div class="term-benefits-row">
                        <div class="term-benefits-item">
                            <img src={death_Benefits} class="term-benefits-img" alt="Death Benefits" />
                            <h3 class="term-benefits-heading">Death Benefits</h3>
                        </div>
                        <div class="term-benefits-item">
                            <img src={Guaranteed_Returns} class="term-benefits-img" alt="Guaranteed Returns" />
                            <h3 class="term-benefits-heading">Guaranteed Returns</h3>
                        </div>
                        <div class="term-benefits-item">
                            <img src={Pension_Fund} class="term-benefits-img" alt="Pension Fund" />
                            <h3 class="term-benefits-heading">Pension Fund</h3>
                        </div>
                    </div>
                </div>

                <div class="term-contact-container">
                    <h3>" Book a free appointment with us today! We're happy to assist you."</h3>
                    <button class="term-contact-button">Free Appointment</button>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Endowment_Plan_Info;