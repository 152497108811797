import React from 'react'
import AccLifeImg from '../../assets/images/Acc_death.avif';
import AccBenefitsImg from '../../assets/images/Accidental_Death_Disability.jpg';
import AccWhoShouldOptImg from '../../assets/images/Personal_Accident_Insurance.avif';
import Navbar from '../../pages/navbar/Navbar'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'

function Accidental_Death_Plans() {
    return (
        <>
            <Navbar />
            <div className="child-plans-container">
                {/* Section 1: What is Group Life Insurance? */}
                <section className="child-plans-section">
                    <h2>What is Accidental Death & Disability Insurance?</h2>
                    <div className="section-content">
                        <img
                            src={AccLifeImg}
                            alt="Group Life Insurance"
                            className="section-img"
                        />
                        <div className="section-text">
                            <p>
                                Accidental Death & Disability Insurance provides financial protection in case of accidental death or serious injuries leading to disability.
                                It ensures that your loved ones receive financial support in case of an unfortunate event.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="child-plans-section">
                    <h2>Key Features of Accidental Death & Disability Insurance</h2>
                    <div className="section-content">
                        <ul>
                            <li><strong>Accidental Death Benefit:</strong> Provides a lump sum payout to the nominee in case of accidental death.</li>
                            <li><strong>Disability Coverage:</strong> Offers financial assistance in case of permanent or partial disability due to an accident.</li>
                            <li><strong>Affordable Premiums:</strong> Cost-effective coverage for unforeseen accidents.</li>
                            <li><strong>Worldwide Coverage:</strong> Protection against accidents anywhere in the world.</li>
                            <li><strong>Quick Claim Process:</strong> Hassle-free and fast claim settlements.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="child-plans-section">
                    <h2>Benefits of Accidental Death & Disability Insurance</h2>
                    <div className="section-content">
                        <img
                            src={AccBenefitsImg}
                            alt="Benefits of Group Life Insurance"
                            className="section-img"
                        />
                        <div className="section-text">
                            <ul>
                                <li>✔ Provides financial security to the family in case of accidental death.</li>
                                <li>✔ Covers medical expenses in case of accidental injuries.</li>
                                <li>✔ Offers income replacement in case of permanent disability.</li>
                                <li>✔ Acts as an additional layer of protection along with health insurance.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Group Life Insurance? */}
                <section className="child-plans-section">
                    <h2>Who Should Opt for This Insurance ?</h2>
                    <div className="section-content">
                        <div className="section-text">
                            <ul>
                                <li>✔ Individuals with high-risk jobs or frequent travelers.</li>
                                <li>✔ People looking for additional financial security beyond health insurance.</li>
                                <li>✔ Families seeking financial protection against accidental uncertainties.</li>
                            </ul>
                        </div>
                        <img
                            src={AccWhoShouldOptImg}
                            alt="Who Should Opt for Group Life Insurance?"
                            className="section-img"
                        />
                    </div>
                </section>

                <div class="term-contact-container">
                    <h3>" Book a free appointment with us today! We're happy to assist you."</h3>
                    <button class="term-contact-button">Free Appointment</button>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Accidental_Death_Plans