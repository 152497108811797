import React, { useEffect, useState, useRef } from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import health_insurance from '../../assets/images/helath_insurance.jpg';
import Individual_Health from '../../assets/images/Individual_Health_Insurance.avif';
import family_health_insurance from '../../assets/images/Family_Health_Insurance.avif';
// import senior_citizen_health from '../../assets/images/Senior_Citizen_Health.png';
import critical_illness from '../../assets/images/Critical_Illness_Insurance.avif';
import maternity_insurance from '../../assets/images/Maternity_Insurance.jpg';
// import top_up_health from '../../assets/images/Top_up_health.png';
import personal_accident from '../../assets/images/Personal_Accident_Insurance.avif';
import medclaim_insurance from '../../assets/images/Mediclaim_Insurance.avif';
import hospital_cash from '../../assets/images/Hospital _Cash_Insurance.avif';
// import disease_specific from '../../assets/images/Disease_specific_health.png';
import { Link } from 'react-router-dom';
import { ImCross } from 'react-icons/im';

function Health_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: Individual_Health,
            title: 'Individual Health Insurance',
            description: 'Provides coverage for an individual’s medical expenses, including hospitalization, surgeries, and consultations with doctors...',
            link: '/individual-health' // Add the appropriate link
        },
        {
            img: family_health_insurance,
            title: 'Family Health Insurance',
            description: 'Offers health coverage for all members of a family, ensuring comprehensive medical care for each family member...',
            link: '/family-health' // Add the appropriate link
        },
        // {
        //     img: senior_citizen_health,
        //     title: 'Senior Citizen Health Insurance',
        //     description: 'Designed to meet the specific healthcare needs of senior citizens, offering coverage for age-related...'
        // },
        {
            img: critical_illness,
            title: 'Critical Illness Insurance',
            description: 'Provides financial support in the event of critical illnesses such as cancer, heart disease, and strokes...'
        },
        {
            img: maternity_insurance,
            title: 'Maternity Insurance',
            description: 'Offers coverage for maternity-related expenses, including prenatal care, childbirth, and postnatal care...'
        },
        // {
        //     img: top_up_health,
        //     title: "Top-up Health Insurance",
        //     description: 'This policy provides additional coverage beyond your base health insurance, helping to cover larger...'
        // },
        {
            img: personal_accident,
            title: "Personal Accident Insurance",
            description: 'Offers protection against accidental injuries, covering hospitalization, surgeries...'
        },
        {
            img: medclaim_insurance,
            title: "Mediclaim Insurance",
            description: 'Provides reimbursement for medical expenses incurred during hospitalization...'
        },
        {
            img: hospital_cash,
            title: "Hospital Cash Insurance",
            description: 'Offers a fixed daily allowance to cover non-medical expenses during hospitalization, such as transportation...'
        },
        // {
        //     img: disease_specific,
        //     title: "Disease-Specific Health Insurance",
        //     description: 'This insurance covers the treatment costs of specific diseases such as diabetes, kidney disease, or cancer...'
        // }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="home_insurance-header-section">
                <Link to="/life-Insurance" className="login-close-link">
                    <ImCross className="login-close-icon" />
                </Link>
                <h1 className="home_insurance-main-title">Health Insurance</h1>
                <p className="home_insurance-subtitle">Protect Your Health and Well-being with Comprehensive Coverage</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        Health insurance provides financial protection against medical expenses, ensuring access to necessary treatments and healthcare services.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Health insurance plans are designed to be affordable while offering essential coverage for medical care.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Clear and easy-to-understand terms, helping individuals navigate their health insurance coverage with ease.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Choose from different plans and add-ons to customize your health insurance policy based on your needs.</p>
                        </div>
                    </div>
                </div>


                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Health Insurance</h2>
                    <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <Link
                                    to={item.link} // Link to the desired page
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="insurance-cta-container">
                    <div className="insurance-cta-image-container">
                        <img
                            src={health_insurance}  // Replace with the image for health insurance
                            alt="Health insurance illustration"
                            className="insurance-cta-image"
                        />
                    </div>
                    <div className="insurance-cta-content">
                        <h1 className="insurance-cta-title">Why Choose Health Insurance?</h1>
                        <p className="insurance-cta-description">
                            Health insurance is vital to protect you and your family from unexpected medical expenses. It provides financial coverage for doctor visits, hospital stays, surgeries, and prescription medications. With the right health insurance plan, you can ensure access to quality healthcare and maintain your well-being without worrying about high medical costs.
                        </p>
                        <ul className="insurance-cta-benefits">
                            <li><strong>Medical Coverage:</strong> Helps cover doctor visits, hospital stays, surgeries, and medications.</li>
                            <li><strong>Emergency Care:</strong> Provides coverage for accidents and critical illnesses.</li>
                            <li><strong>Preventive Care:</strong> Covers routine check-ups, vaccinations, and screenings.</li>
                            <li><strong>Healthcare Access:</strong> Ensures access to doctors, hospitals, and specialists in your network.</li>
                            <li><strong>Financial Protection:</strong> Reduces medical costs and avoids large out-of-pocket expenses.</li>
                            <li><strong>Peace of Mind:</strong> Be confident knowing you're covered for illness, injury, or emergencies.</li>
                        </ul>

                        <p className="insurance-cta-footer">
                            Protect your health and your future—get health insurance today.
                        </p>

                        <Link to="/Signup">
                            <button className="insurance-cta-button">Get Your Health Insurance</button>
                        </Link>
                    </div>
                </div>

                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title">Benefits of Health Insurance</h2>
                    <div className='home_insurance-benefits-inner-section'>
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Health insurance ensures financial protection from high medical costs, offering access to quality healthcare services.</li>
                                <li>It covers a wide range of medical treatments, from hospitalization to routine check-ups and specialist visits.</li>
                                <li>Health insurance can also provide coverage for preventive care, helping to avoid more serious health issues down the road.</li>
                                <li>Many health insurance policies offer flexible coverage options, allowing you to select the services you need most.</li>
                                <li>Health insurance can offer peace of mind, knowing you’re protected against unexpected medical expenses.</li>
                                <li>For more benefits, Book a free appointment with us.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                        <div>
                            <img src={health_insurance} alt="Benefits of Home Insurance" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Health_Insurance