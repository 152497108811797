import React, { useState, useEffect } from 'react';
import adminLogo from '../../assets/images/upholic.newlogo.png'; // Update the path to your logo
import AdminSidebarLinks from '../admin_sidebar_links/Admin_sidebar_links';
import axios from 'axios';

function Admin_Third_Party() {
    const [resumeAccordionOpen, setResumeAccordionOpen] = useState(false);
    const [tcpdata, settcpdata] = useState([]); // State to store third-party data
    const [error, setError] = useState(null); // Error state for handling errors
    const [cards, setCards] = useState([
        { name: "Total Company", icon: "business-outline", count: 0 },
        { name: "Total Customer", icon: "man-outline", count: 0 },
        { name: "Deposit Amt", icon: "cash-outline", count: 0 },
        { name: "Deposit Per", icon: "pricetag-outline", count: 0 },
        { name: "Deposit Com", icon: "calculator-outline", count: 0 }
        
    ]);
    const [searchQuery, setSearchQuery] = useState(''); // State to store the search query
    const [filteredData, setFilteredData] = useState([]); // State for filtered data

    useEffect(() => {
        fetchThirdPartyData();
        fetchCardData();
    }, []);

    useEffect(() => {
        // Filter data when searchQuery changes
        if (searchQuery) {
            const filtered = tcpdata.filter((tpc) =>
                tpc.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                tpc.customerName.toUpperCase().includes(searchQuery.toUpperCase()) ||
                tpc.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                tpc.companyName.toUpperCase().includes(searchQuery.toUpperCase()) ||
                tpc.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                tpc.phoneNumber.toUpperCase().includes(searchQuery.toUpperCase())
            );            
            setFilteredData(filtered);
        } else {
            setFilteredData(tcpdata); // Reset to original data if search query is empty
        }
    }, [searchQuery, tcpdata]);

    // Fetch third-party data
    const fetchThirdPartyData = async () => {
        try {
            const response = await axios.get('https://www.upholic.in/Upholic_Api/third_party_data_fetch.php'); // Update with your API URL
            settcpdata(response.data);
            setFilteredData(response.data); // Set initial filtered data
        } catch (err) {
            setError("Failed to fetch data. Please try again.");
            console.error("Error fetching third-party data:", err);
        }
    };

    // Fetch dynamic data for cards
    const fetchCardData = async () => {
        try {
            const response = await axios.get('https://www.upholic.in/Upholic_Api/cards_data_fetch.php'); // Update with your API URL for cards data
            setCards(prevState => prevState.map((card, index) => ({
                ...card,
                count: response.data[index].count // Assuming your response has a 'count' property
            })));
        } catch (err) {
            console.error("Error fetching card data:", err);
        }
    };

    const toggleResumeAccordion = () => {
        setResumeAccordionOpen(prevState => !prevState);
    };

    const openPopup = (tpc) => {
        // Logic to open a popup with the selected third-party data
        // console.log(tpc);
    };

    const handleSearchClick = (e) => {
        e.stopPropagation(); // Prevents the click event from bubbling up to the accordion header
    };

    return (
        <>
            <AdminSidebarLinks />
            <div className="man-man-div">
                <div className="deposite-header area">
                    <ul className="circles">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <li key={index}></li>
                        ))}
                    </ul>
                    <div className="deposite-container">
                        <div className="deposite-logo-img">
                            <img src={adminLogo} alt="Admin Logo" className="deposite-image-logo" />
                        </div>
                        <div className="deposite-search-bar">
                            <input type="text" placeholder="Search here" />
                            <ion-icon name="search" className="search-icon" />
                        </div>
                        <div className="deposite-logo2-bar">
                            <h1 className="deposite-page-headingg">Third Party</h1>
                        </div>
                    </div>

                    {/* ======================= Cards ================== */}
                    <div className="deposite-cardBox">
                        {cards.map((card, index) => (
                            <div className="deposite-card" key={index}>
                                <div>
                                    <div className="deposite-numbers">{card.count}</div>
                                    <div className="deposite-cardName">{card.name}</div>
                                </div>
                                <div className="deposite-iconBx">
                                    <ion-icon name={card.icon} />
                                </div>
                            </div>
                        ))}
                        {error && <div className="error-message">{error}</div>}
                    </div>
                </div>

                <div className="pms_dashboard_areaa">
                    <ul className="pms_dashboard_circles">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <li key={index}></li>
                        ))}
                    </ul>
                    <div className="Pms_dashboard_card">
                        <div
                            className="Pms_dashboard_section_title"
                            onClick={toggleResumeAccordion}
                            style={{ cursor: "pointer" }}
                        >
                            Third Party
                            <div className="pms_dashboard_search-container">
                                <input
                                    type="search"
                                    placeholder="Search..."
                                    className="pms_dashboard_search-input"
                                    onClick={handleSearchClick} // Prevent toggle when clicking the search input
                                    value={searchQuery} // Bind the search query to the input
                                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
                                />
                            </div>
                        </div>
                        {resumeAccordionOpen && (
                            <div className="table-wrapper">
                                {error && <div className="error-message">{error}</div>}
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Customer ID</th>
                                            <th>Company Name</th>
                                            <th>Customer Name</th>
                                            <th>Phone Number</th>
                                            <th>Date of Investment</th>
                                            <th>Amount</th>
                                            <th>Percentage</th>
                                            <th>Commission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? (
                                            filteredData.map((tpc, index) => (
                                                <tr key={index}>
                                                    <td style={{ cursor: "pointer", color: "gold" }} onClick={() => openPopup(tpc)}>
                                                        {index + 1}
                                                    </td>
                                                    <td>{tpc.customerID || "N/A"}</td>
                                                    <td style={{ cursor: "pointer", color: "gold" }} onClick={() => openPopup(tpc)}>
                                                        {tpc.companyName || "N/A"}
                                                    </td>
                                                    <td>{tpc.customerName || "N/A"}</td>
                                                    <td>{tpc.phoneNumber || "N/A"}</td>
                                                    <td>{tpc.investmentDate === "0000-00-00" ? "Invalid Date" : tpc.investmentDate || "N/A"}</td>
                                                    <td>{tpc.amount || "N/A"}</td>
                                                    <td>{tpc.percentage || "N/A"}</td>
                                                    <td>{tpc.commission || "N/A"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Admin_Third_Party;
