import React from 'react';
import './Term_Insurance_Info.css';
import term_insurance from '../../assets/images/term_insurance_info.png';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';

function Term_Insurance_Info() {
    return (
        <>
            <Navbar />
            <div className='term-insurance-container'>
                <h1 className='term-heading'>Term Insurance</h1>
                <div className='term-content'>

                    {/* Image Section */}
                    <div className='term-image-container'>
                        <img
                            src={term_insurance}
                            alt='Term Insurance'
                            className='term-image'
                        />
                    </div>

                    {/* Text Section */}
                    <div className='term-text-container'>
                        <h3 className='term-subheading'>What Is a Term Insurance?</h3>
                        <p className='term-description'>
                            Term insurance is a type of life insurance that provides coverage for a specific period, or term, of time.
                            It offers financial protection to the policyholder’s beneficiaries in the event of the policyholder’s death during the term.
                            Unlike other types of life insurance, such as whole life or universal life insurance, term insurance does not accumulate a cash value over time.
                            The primary benefit of term insurance is its affordability compared to other life insurance options.
                            It allows individuals to obtain a higher coverage amount for a lower premium, making it an attractive choice for those seeking temporary financial protection.
                            However, once the term expires, the coverage ends, and no benefits are paid if the policyholder survives the term.
                            Term insurance is commonly used to provide for dependents, pay off debts, or cover financial obligations that may diminish over time, such as mortgages or educational expenses.
                        </p>
                        <button className='learn-more-btn'>Learn More</button>
                    </div>
                </div>

                <div class="term-contact-container">
                    <h3>" Book a free appointment with us today! We're happy to assist you."</h3>
                    <button class="term-contact-button">Free Appointment</button>
                </div>

            </div>
            <Footer />
            <Copyright />
        </>
    );
}

export default Term_Insurance_Info;
