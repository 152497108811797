import React, { useState, useEffect } from "react";
import axios from "axios";

function InsuranceEntry() {
  const [formData, setFormData] = useState({
    user_id: "", // Added user_id initialization
    Policy_name_holder: "",
    Policy_company_name: "",
    I_will_be_coverd_for: "",
    I_will_pay_premium: "",
    Premium_Frequency: "",
    Premium_amount: "",
    Life_Cover: "",
    Life_Cover_You_Pay: "", // Added new field
    phone_number: "",
    Application_Date: "",
    Application_Number: "",
    exit: "", // Added exit field
    active: "Active", // Default value for active field
  });
  // console.log(formData);  // Log the form data to ensure it's populated correctly


  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false); // Track if it's an update or insert

  // Toggle accordion open/close
  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ensure valid date format for 'exit' and 'Application_Date'
    if ((name === "exit" || name === "Application_Date") && value !== "") {
      // If value is '0000-00-00', set it to an empty string
      if (value === "0000-00-00") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: "",
        }));
        return;
      }
    }

    // Otherwise, update form data for all fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Automatically fetch data when phone number or user_id changes
    if ((name === "phone_number" || name === "user_id") && value) {
      handleFetch(value); // Trigger the fetch operation
    }

    // If phone number is removed, clear the form data
    if (name === "phone_number" && value === "") {
      setFormData({
        user_id: "",
        Policy_name_holder: "",
        Policy_company_name: "",
        I_will_be_coverd_for: "",
        I_will_pay_premium: "",
        Premium_Frequency: "",
        Premium_amount: "",
        Life_Cover: "",
        Life_Cover_You_Pay: "",
        phone_number: "",
        Application_Date: "",
        Application_Number: "",
        exit: "", // Reset exit field
        active: "Active", // Reset active field
      });
      setIsUpdate(false); // Switch to "Insert" mode
    }
  };

  // Handle fetch operation (fetch data by user_id or phone_number)
  const handleFetch = async (value) => {
    // console.log(value);
    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insurance_plan.php",
        {
          action: "fetch",
          identifier: value, // This will be either phone_number or user_id
        }
      );

      if (response.data.data) {
        // Check for invalid dates in the fetched data
        const data = response.data.data;
        if (data.exit === "0000-00-00") {
          data.exit = ""; // Handle invalid date
        }

        setFormData(data);
        setSuccessMessage("Record fetched successfully.");
        setErrorMessage("");
        setIsUpdate(true); // Set to Update mode
      } else {
        setErrorMessage(response.data.message || "No record found.");
        setSuccessMessage("");
        setIsUpdate(false); // Set to Insert mode if no record found
      }
    } catch (error) {
      setErrorMessage("Error occurred while fetching data.");
      setSuccessMessage("");
      setIsUpdate(false);
    }
  };

  // Handle form submission for insertion or update
  const handleInsertOrUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insurance_plan.php",
        {
          action: isUpdate ? "update" : "insert",
          ...formData,
        }
      );
      // console.log(response);

      if (response.data.message) {
        setSuccessMessage(response.data.message);
        setErrorMessage("");

        // Reset form after successful submission
        setFormData({
          user_id: "",
          Policy_name_holder: "",
          Policy_company_name: "",
          I_will_be_coverd_for: "",
          I_will_pay_premium: "",
          Premium_Frequency: "",
          Premium_amount: "",
          Life_Cover: "",
          Life_Cover_You_Pay: "",
          phone_number: "",
          Application_Date: "",
          Application_Number: "",
          exit: "",
          active: "Active",
        });
      }
    } catch (error) {
      setErrorMessage("Error occurred while inserting/updating data.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            Insurance Plan Data
          </div>
          {accordionOpen && (
            <>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}

              <form
                className="Pms_insert_user_kyc-form"
                onSubmit={handleInsertOrUpdate}
              >
                <div className="Pms_insert_user_kyc-row">
                  <label>User ID:</label>
                  <input
                    type="text"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label>Policy Name Holder:</label>
                  <input
                    type="text"
                    name="Policy_name_holder"
                    value={formData.Policy_name_holder}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Policy Company Name:</label>
                  <input
                    type="text"
                    name="Policy_company_name"
                    value={formData.Policy_company_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>I Will Be Covered For:</label>
                  <select
                    name="I_will_be_coverd_for"
                    value={formData.I_will_be_coverd_for}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="10 years">10 years</option>
                    <option value="20 years">20 years</option>
                    <option value="life time">Life Time</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Life Cover You Pay:</label>
                  <input
                    type="text"
                    name="Life_Cover_You_Pay"
                    value={formData.Life_Cover_You_Pay}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>I Will Pay Premium:</label>
                  <select
                    name="I_will_pay_premium"
                    value={formData.I_will_pay_premium}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="10 years">10 years</option>
                    <option value="20 years">20 years</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Premium Frequency:</label>
                  <select
                    name="Premium_Frequency"
                    value={formData.Premium_Frequency}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">--Select Premium--</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Annually">Annually</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Premium Amount:</label>
                  <input
                    type="text"
                    name="Premium_amount"
                    value={formData.Premium_amount}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Life Cover:</label>
                  <select
                    name="Life_Cover"
                    value={formData.Life_Cover}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">--Select Cover--</option>
                    <option value="10lac">10lac</option>
                    <option value="20lac">20lac</option>
                    <option value="50lac">50lac</option>
                    <option value="70lac">70lac</option>
                    <option value="1cr">1cr</option>
                    <option value="2cr">2cr</option>
                    <option value="5cr">5cr</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Application Date:</label>
                  <input
                    type="date"
                    name="Application_Date"
                    value={formData.Application_Date}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Application Number:</label>
                  <input
                    type="text"
                    name="Application_Number"
                    value={formData.Application_Number}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Exit Date:</label>
                  <input
                    type="date"
                    name="exit"
                    value={formData.exit === "0000-00-00" ? "" : formData.exit} // Handle invalid date format
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Active:</label>
                  <select
                    name="active"
                    value={formData.active}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>

                <div className="investment-row">
                  <button type="submit" className="edit-button">
                    {isUpdate ? "Update" : "Insert"}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default InsuranceEntry;
