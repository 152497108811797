import React, { useState } from 'react';
import axios from 'axios';

function Fact() {
  const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false); // Modal state

    const handleSubmit = async () => {
        if (!name || !phone) {
            setMessage('Please fill in all fields.');
            return;
        }

        try {
            const response = await axios.post('https://www.upholic.in/Upholic_Api/insert_appointment.php', {
                name: name,
                phone: phone
            });

            if (response.data.success) {
                setMessage('Appointment booked successfully!');
                setShowModal(true); // Show success popup
                setName('');
                setPhone('');
            } else {
                setMessage('Failed to book appointment. Please try again.');
            }
        } catch (error) {
            console.error('Error booking appointment:', error);
            setMessage('Error booking appointment. Please try again later.');
        }
    };

    return (
        <div className="appointment-main">
            <div className="appointment-container">
                <h1 className="appointment-title">Book your free appointment</h1>
                <p className="appointment-description">
                    Focus on what matters most. We're committed to providing you exceptional value.
                </p>
                <div className="appointment-form">
                    <input
                        className="appointment-input"
                        type="text"
                        placeholder="Your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        className="appointment-input"
                        type="tel"
                        placeholder="Your phone number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <button className="appointment-button" onClick={handleSubmit}>Try it Now</button>
                </div>
            </div>

            {/* Success Modal Popup */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>🎉 Appointment Confirmed!</h2>
                        <p>Your appointment has been successfully booked.</p>
                        <button className="modal-close-button" onClick={() => setShowModal(false)}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Fact;