import React from 'react';
import './Child_Plan_Info.css';
import ChildPlanImg from '../../assets/images/Child_Plans.avif';
import EducationPlanImg from '../../assets/images/Child_Education_Plans.jpg';
import ChildBenefitsImg from '../../assets/images/Benefits_Child_Insurance.avif';
import WhyNeedImg from '../../assets/images/Child_Ins.avif';
import TaxBenefitsImg from '../../assets/images/Tax_Benefits.png';
import DocumentsImg from '../../assets/images/Documents_Req.png';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';

function Child_Plan_Info() {
    return (
        <>
            <Navbar />
            <div className="child-plans-container">
                {/* Section 1: What are Child Plans? */}
                <section className="child-plans-section">
                    <h2>What are Child Plans?</h2>
                    <div className="section-content">
                        <img src={ChildPlanImg} alt="Child Plans" className="section-img" />
                        <div className="section-text">
                            <p>
                                Child plans are like magical treasure chests that hold dreams, hopes, and financial security for little ones. These enchanting insurance products wrap children in a protective shield while nurturing their future aspirations. They are like loyal guardians, providing both life insurance coverage and a secret garden of savings. With each passing day, these plans grow like vibrant flowers, accumulating funds that bloom into a bountiful harvest when the child reaches important milestones. Whether it’s education, marriage, or career pursuits, child plans stand as beacons of support, ensuring that the young adventurers embark on their journeys with confidence and financial stability.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: What are Child Education Plans? */}
                <section className="child-plans-section">
                    <h2>What are Child Education Plans?</h2>
                    <div className="section-content">
                        <div className="section-text">
                            <p>
                                A child education plan is a magical compass that guides parents on a treasure hunt for their little ones’ educational dreams.
                                It is a secret map to financial security, sprinkled with the promise of a bright future. With each contribution, it builds a fortress of funds, paving the way for knowledge and wisdom.
                                It’s an enchanting potion that transforms dreams into reality, ensuring that their educational voyage is filled with wonder and opportunity.
                            </p>
                        </div>
                        <img
                            src={EducationPlanImg}
                            alt="Child Education Plans"
                            className="section-img"
                        />
                    </div>
                </section>

                {/* Section 3: Benefits of Child Insurance */}
                <section className="child-plans-section">
                    <h2>Benefits of a Child Insurance for Your Child’s Education</h2>
                    <div className="section-content">
                        <img
                            src={ChildBenefitsImg}
                            alt="Benefits of Child Insurance"
                            className="section-img"
                        />
                        <div className="section-text">
                            <p>
                                Secure your child’s educational journey with the wings of protection through child insurance.
                                Unleash the potential of their dreams while shielding them from uncertainties. With this invaluable investment, you gift them a treasure trove of opportunities, ensuring their future brilliance soars high with confidence and promise.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 4: Why You Need a Child Insurance Plan */}
                <section className="child-plans-section">
                    <h2>Why You Need a Child Insurance Plan</h2>
                    <div className="section-content">
                        <div className="section-text">
                            <p>
                                In today’s world, a child insurance plan is more crucial than ever. It serves as a protective shield for your child’s future, ensuring their dreams are realized, even in the face of unforeseen circumstances. This plan provides financial security to meet educational expenses, from school fees to higher education. It also offers peace of mind, knowing that your child’s aspirations won’t be compromised due to financial constraints. Additionally, child insurance can provide benefits like tax savings and flexible savings options. It empowers parents to nurture their child’s potential, knowing that their education and future are safeguarded.
                            </p>
                            <p>
                                <strong>Example:</strong> Embrace the power of a child insurance plan, where your little one’s dreams find unwavering support. From kindergarten to college, it ensures a secure future, shielding them from financial obstacles that could hinder their educational journey. With peace of mind and tax benefits as companions, you’ll witness your child’s aspirations blossoming, as they unlock a world of possibilities.
                            </p>
                        </div>
                        <img
                            src={WhyNeedImg}
                            alt="Why You Need a Child Insurance Plan"
                            className="section-img"
                        />
                    </div>
                </section>

                {/* Section 5: Tax Benefits */}
                <section className="child-plans-section">
                    <h2>Tax Benefits with a Child Education Plan</h2>
                    <div className="section-content">
                        <img
                            src={TaxBenefitsImg}
                            alt="Tax Benefits"
                            className="section-img"
                        />
                        <div className="section-text">
                            <ul>
                                <li>
                                    <strong>Tax deductions under Section 80C:</strong> The premiums paid towards a child education plan are eligible for tax deductions of up to Rs. 1.5 lakh per financial year. This reduces your taxable income, resulting in lower tax liability.
                                </li>
                                <li>
                                    <strong>Exemption on maturity benefits:</strong> Any maturity benefits received from the child education plan are exempted from tax under Section 10(10D). This means that the payout you receive upon maturity or in the form of bonuses or dividends is entirely tax-free.
                                </li>
                                <li>
                                    <strong>Long-term tax planning:</strong> By investing in a child education plan, you can strategically utilize the tax benefits to create a long-term tax-saving plan. It allows you to build a corpus for your child’s education while simultaneously reducing your overall tax liability.
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 6: Comparison Table */}
                <section className="child-plans-section">
                    <h2>Child Plan vs Sukanya Samriddhi Yojna (SSY) vs Public Provident Fund (PPF)</h2>
                    <div className="section-content">
                        <table className="comparison-table">
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Child Plan</th>
                                    <th>Sukanya Samriddhi Yojana (SSY)</th>
                                    <th>Public Provident Fund (PPF)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Purpose</td>
                                    <td>Insurance-cum-investment for child’s future</td>
                                    <td>Education and marriage of a girl child</td>
                                    <td>Long-term savings and retirement planning</td>
                                </tr>
                                <tr>
                                    <td>Investment Limit</td>
                                    <td>Varies based on the plan and premium chosen</td>
                                    <td>Maximum of Rs. 1.50 lakh per year</td>
                                    <td>Maximum of Rs. 1.50 lakh per year</td>
                                </tr>
                                <tr>
                                    <td>Lock-in Period</td>
                                    <td>Varies based on the plan</td>
                                    <td>Until the girl child turns 21 years old</td>
                                    <td>15 years (extendable in blocks of 5 years)</td>
                                </tr>
                                <tr>
                                    <td>Returns</td>
                                    <td>Varies based on the plan and market performance</td>
                                    <td>Government-set interest rates</td>
                                    <td>Government-set interest rates</td>
                                </tr>
                                <tr>
                                    <td>Tax Benefits</td>
                                    <td>Tax deduction on premiums and maturity benefits</td>
                                    <td>EEE (Exempt, Exempt, Exempt)</td>
                                    <td>Tax deduction on investments and interest</td>
                                </tr>
                                <tr>
                                    <td>Insurance Coverage</td>
                                    <td>Life cover for the parent or child (based on the plan)</td>
                                    <td>No insurance component</td>
                                    <td>No insurance component</td>
                                </tr>
                                <tr>
                                    <td>Withdrawal Restrictions</td>
                                    <td>Varies based on the plan and policy terms</td>
                                    <td>Partial withdrawals allowed after the girl child turns 18</td>
                                    <td>Partial withdrawals allowed from the 7th year onwards</td>
                                </tr>
                                <tr>
                                    <td>Accessibility</td>
                                    <td>Offered by insurance companies</td>
                                    <td>Offered by post offices and authorized banks</td>
                                    <td>Offered by post offices and authorized banks</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                {/* Section 7: Documents Required */}
                <section className="child-plans-section">
                    <h2>Documents Required for Child Insurance Plans</h2>
                    <div className="section-content">
                        <img
                            src={DocumentsImg}
                            alt="Documents Required"
                            className="section-img"
                        />
                        <div className="section-text">
                            <p>
                                Buying an insurance plan in India is very easy. You only need to submit the following documents:
                            </p>
                            <ul>
                                <li>Proof of age such as Birth Certificate, Mark Sheet, Passport, etc.</li>
                                <li>Proof of identity such as Aadhar Card, Passport, PAN Card, Voter ID, etc.</li>
                                <li>Proof of income such as Financial Statements, ITR, etc.</li>
                                <li>Proof of address such as Telephone Bill, Electricity Bill, Ration Card, Passport, Driver’s Licence, etc.</li>
                                <li>Proposal form duly and truthfully filled.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div class="term-contact-container">
                    <h3>" Book a free appointment with us today! We're happy to assist you."</h3>
                    <button class="term-contact-button">Free Appointment</button>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    );
}

export default Child_Plan_Info;