import React from 'react';
import MoneyBackImg from '../../assets/images/Money_Back_Plan.jpg';
import MoneyBackBenefitsImg from '../../assets/images/MoneyBack_Benefits.png';
import MoneyBackWhoShouldOptImg from '../../assets/images/Saving_Practice.png';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';

function Money_Plan() {
    return (
        <>
            <Navbar />
            <div className="child-plans-container">
                {/* Section 1: What is Money Back Plan? */}
                <section className="child-plans-section">
                    <h2>What is Money Back Plan?</h2>
                    <div className="section-content">
                        <img
                            src={MoneyBackImg}
                            alt="Money Back Plan"
                            className="section-img"
                        />
                        <div className="section-text">
                            <p>
                                A Money Back Plan provides periodic payouts at regular intervals, ensuring liquidity while offering life cover.
                                It combines the benefits of savings and insurance, making it an ideal choice for individuals looking for financial security and periodic returns.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="child-plans-section">
                    <h2>Key Features of Money Back Plan</h2>
                    <div className="section-content">
                        <ul>
                            <li><strong>Regular Payouts:</strong> Get assured returns at specific intervals during the policy term.</li>
                            <li><strong>Life Cover:</strong> Provides financial security to your family in case of unforeseen circumstances.</li>
                            <li><strong>Guaranteed Maturity Benefit:</strong> Receive a lump sum amount along with bonuses at maturity.</li>
                            <li><strong>Survival Benefits:</strong> Get periodic payouts even while the policy is active.</li>
                            <li><strong>Tax Benefits:</strong> Avail tax benefits under Section 80C and 10(10D) of the Income Tax Act.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="child-plans-section">
                    <h2>Benefits of Money Back Plan</h2>
                    <div className="section-content">
                        <img
                            src={MoneyBackBenefitsImg}
                            alt="Benefits of Money Back Plan"
                            className="section-img"
                        />
                        <div className="section-text">
                            <ul>
                                <li>✔ Ensures liquidity with regular payouts at specific intervals.</li>
                                <li>✔ Provides financial protection to your family in case of an unfortunate event.</li>
                                <li>✔ Acts as a disciplined savings tool with guaranteed returns.</li>
                                <li>✔ Offers tax benefits on premiums paid and maturity benefits received.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Who Should Opt for This Insurance? */}
                <section className="child-plans-section">
                    <h2>Who Should Opt for This Insurance?</h2>
                    <div className="section-content">
                        <img
                            src={MoneyBackWhoShouldOptImg}
                            alt="Who Should Opt for Money Back Plan?"
                            className="section-img"
                        />
                        <div className="section-text">
                            <ul>
                                <li>✔ Individuals looking for a plan with periodic payouts.</li>
                                <li>✔ People who want a disciplined savings plan with guaranteed returns.</li>
                                <li>✔ Families seeking financial security and liquidity for future expenses.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div class="term-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <button class="term-contact-button">Free Appointment</button>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Money_Plan