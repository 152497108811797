import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Myprofile.scss"; // Ensure you have the custom styles
import img from "../../assets/images/profile.png"; // Default profile image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function Myprofile() {
  const [profileData, setProfileData] = useState({});
  const [imageSrc, setImageSrc] = useState(null); // State to store the fetched image source
  const [isLoading, setIsLoading] = useState(true); // Loading state for the image
  const cookies = new Cookies();
  const userId = cookies.get("id");

  useEffect(() => {
    axios
      .get(`https://www.upholic.in/Upholic_Api/profile.php?user_id=${userId}`)
      .then((response) => {
        console.log("Response data:", response.data); // Log the response data
        setProfileData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [userId]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!userId) {
          console.error("User ID not found.");
          return;
        }

        const imageUrl = `https://www.upholic.in/Upholic_Api/uploads/${encodeURIComponent(
          userId
        )}_profile.png?timestamp=${new Date().getTime()}`;
        // console.log("Image URL:", imageUrl); // Debugging statement

        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/imageget.php",
          {
            params: { url: imageUrl },
            responseType: "blob",
          }
        );

        // console.log("Axios Response:", response);

        if (response.status === 200) {
          const imageUrlBlob = URL.createObjectURL(response.data);
          setImageSrc(imageUrlBlob);
          // console.log("Image Src:", imageUrlBlob); // Debugging statement
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching the image:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };

    fetchImage();
  }, [userId]);

  useEffect(() => {
    // console.log("Profile Data:", profileData); // Log profile data after it is set
  }, [profileData]);

  if (isLoading || !profileData || Object.keys(profileData).length === 0) {
    return <div>Loading...</div>;
  }
  
  return (
    <div className="container-xl px-4 mt-4">
      <div className="card mb-4">
        <div className="card-header bg-primary text-white">Profile Details</div>
        <div className="card-body text-center">
          <div>
            {imageSrc ? (
              <img
                className="rounded-circle mb-3 myprofileimg"
                src={imageSrc}
                alt="profile"
                onError={(e) => {
                  e.target.src = img; // Fallback image
                  console.error("Error loading image:", e);
                }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <div className="small font-italic text-muted mb-4">
            {profileData.username}
          </div>
          <hr />
          <div className="text-left">
            <div className="row mb-3">
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Username:</strong> {profileData.username}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>First Name:</strong> {profileData.first_name}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Last Name:</strong> {profileData.last_name}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Date of Birth:</strong> {profileData.date_of_birth}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Mobile Number:</strong> {profileData.mobile_number}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Email Address:</strong> {profileData.email_address}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Marital Status:</strong> {profileData.marital_status}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Gender:</strong> {profileData.gender}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Nominee:</strong> {profileData.nominee_name}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Bank Name:</strong> {profileData.bank_name}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>Bank Account:</strong> {profileData.bank_account}
                </p>
              </div>
              <div className="col-md-4">
                <p className="form-label">
                  <strong>IFSC Code:</strong> {profileData.ifsc_code}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <p className="form-label">
                  <strong>Aadhaar Number: </strong> 
                  {profileData.aadhaar_image ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success ml-2" />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger ml-2" />
                  )}
                </p>
              </div>
              <div className="col-md-6">
                <p className="form-label">
                  <strong>PAN Number: </strong> 
                  {profileData.pan_image ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success ml-2" />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger ml-2" />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myprofile;
