import React, { useState } from "react";
import Deposit_Entry from "../deposit_table_entry/Deposit_Entry";
import Pms_insert_user_kyc from "../pms_insert_user_kyc/Pms_insert_user_kyc";
import Pms_login_data from "../pms_login_entry/Pms_login_data";
// import Deposit_Calculation from "../deposit_calculation_entry/Deposit_Calculation";

function Deposit_Data_Dashboard() {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    // console.log("Toggling Accordion. Current state:", accordionOpen);
    setAccordionOpen(prev => !prev); // Toggle with previous state
  };

  return (
    <div className="pms_dashboard_area">
      {/* UL List */}
      <ul className="pms_dashboard_circles">
        {Array(10)
          .fill()
          .map((_, index) => (
            <li key={index}></li>
          ))}
      </ul>
      
      {/* Accordion Card */}
      <div className="Pms_dashboard_card">
        <div
          className="Pms_dashboard_section_title"
          onClick={toggleAccordion}
        >
          Deposit
        </div>

        {/* Only show the accordion content if accordionOpen is true */}
        {accordionOpen && (
          <div className="accordion-content">
            <Deposit_Entry />
            <Pms_insert_user_kyc />
            <Pms_login_data />
          </div>
        )}
      </div>
    </div>
  );
}

export default Deposit_Data_Dashboard;
