import React, { useState, useEffect, useRef } from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import travel_insurance from '../../assets/images/travel_Insurance.avif';
import Domestic_travel from '../../assets/images/Domestic_Travel_Insurance.jpg';
import inter_travel from '../../assets/images/International_Travel_Insurance.avif';
import group_travel from '../../assets/images/Group_Travel_Insurance.jpg';
import single_travel from '../../assets/images/Single_Trip_Travel_Insurance.avif';
import multi_travel from '../../assets/images/Multi_trip_Travel_Insurance.jpg';
import senior_citizen from '../../assets/images/Senior_Citizen_Travel_Insurance.jpeg';
import student_travel from '../../assets/images/Student_Travel_Insurance.avif';
import { Link } from 'react-router-dom';
import { ImCross } from 'react-icons/im';

function Travel_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: Domestic_travel,
            title: 'Domestic Travel Insurance',
            description: 'Covers unforeseen incidents like trip cancellations, delays, and medical emergencies within your home country.'
        },
        {
            img: inter_travel,
            title: 'International Travel Insurance',
            description: 'Provides comprehensive coverage for overseas trips, including medical emergencies, lost passports, and trip disruptions.'
        },
        {
            img: group_travel,
            title: 'Group Travel Insurance',
            description: 'Designed for groups traveling together, offering shared coverage for medical and trip-related contingencies.'
        },
        {
            img: single_travel,
            title: 'Single Trip Travel Insurance',
            description: 'Provides coverage for a one-time trip, ensuring peace of mind throughout your journey.'
        },
        {
            img: multi_travel,
            title: 'Multi-trip Travel Insurance',
            description: 'Ideal for frequent travelers, covering multiple trips within a specified period under one policy.'
        },
        {
            img: senior_citizen,
            title: "Senior Citizen Travel Insurance",
            description: 'Specially designed for senior travelers, offering coverage for medical emergencies and travel disruptions.'
        },
        {
            img: student_travel,
            title: "Student Travel Insurance",
            description: 'Covers students traveling abroad for education, offering protection against medical emergencies and trip-related issues.'
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="home_insurance-header-section">
                <Link to="/life-Insurance" className="login-close-link">
                    <ImCross className="login-close-icon" />
                </Link>
                <h1 className="home_insurance-main-title">Travel Insurance</h1>
                <p className="home_insurance-subtitle">Secure Your Journey with Comprehensive Travel Insurance Coverage</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        Travel insurance provides financial protection and assistance during your trips, covering unforeseen events such as medical emergencies, trip cancellations, and lost luggage.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Travel insurance plans are cost-effective and provide essential coverage for a worry-free journey.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Easy-to-understand policies that ensure clarity in coverage and claims process for travelers.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Customize your travel insurance policy with add-ons and tailored coverage options for your trip.</p>
                        </div>
                    </div>
                </div>


                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Travel Insurance</h2>
                   <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <Link
                                    to="/book-free-appointment"  // Link to the desired page
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="insurance-cta-container">
                    <div className="insurance-cta-image-container">
                        <img
                            src={travel_insurance}  // Replace with the image for travel insurance
                            alt="Travel insurance illustration"
                            className="insurance-cta-image"
                        />
                    </div>
                    <div className="insurance-cta-content">
                        <h1 className="insurance-cta-title">Why Choose Travel Insurance?</h1>
                        <p className="insurance-cta-description">
                            Travel insurance provides essential coverage to protect you from unforeseen events while traveling. Whether you're going on a business trip, vacation, or adventure, travel insurance ensures you’re covered for medical emergencies, trip cancellations, lost luggage, and other unexpected incidents. Don't let a mishap ruin your trip—get travel insurance for peace of mind.
                        </p>
                        <ul className="insurance-cta-benefits">
                            <li><strong>Emergency Medical Coverage:</strong> Covers medical expenses for illness or injury while traveling.</li>
                            <li><strong>Trip Cancellation:</strong> Reimburses non-refundable expenses if you need to cancel or shorten your trip.</li>
                            <li><strong>Lost or Stolen Luggage:</strong> Covers lost, stolen, or damaged baggage during your trip.</li>
                            <li><strong>Travel Delays:</strong> Compensates for extra costs due to flight delays or cancellations.</li>
                            <li><strong>Personal Liability Protection:</strong> Covers legal costs for injuries or damages you may cause while traveling.</li>
                            <li><strong>Peace of Mind:</strong> Travel with confidence, knowing you're protected in emergencies.</li>
                        </ul>

                        <p className="insurance-cta-footer">
                            Travel with peace of mind—protect yourself and your trip with travel insurance.
                        </p>

                        <Link to="/Signup">
                            <button className="insurance-cta-button">Get Your Travel Insurance</button>
                        </Link>
                    </div>
                </div>


                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title">Benefits of Travel Insurance</h2>
                    <div className="home_insurance-benefits-inner-section">
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Travel insurance provides comprehensive financial protection against unexpected expenses, ensuring you're covered for unforeseen situations during your trips.</li>
                                <li>It covers a wide range of scenarios, including medical emergencies, trip cancellations, lost luggage, delayed flights, and other travel-related inconveniences.</li>
                                <li>With 24/7 assistance, help is always just a phone call away, providing support and guidance no matter where you are in the world.</li>
                                <li>Offers flexible coverage options that can be tailored to your specific travel needs, whether for a short trip, a long vacation, or multiple journeys.</li>
                                <li>Ensures complete peace of mind, allowing you to relax and focus on enjoying your journey without worrying about potential mishaps or costs.</li>
                                <li>Discover even more benefits by booking a free appointment with our team of experts to choose the right travel insurance plan for you.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                        <div>
                            <img src={travel_insurance} alt="Benefits of Travel Insurance" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Travel_Insurance;
