import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Notification.css';
import Cookies from "universal-cookie";

const Notification = ({ isVisible, onClose }) => {
  const cookies = new Cookies();
  const userId = cookies.get("id");

  const [notifications, setNotifications] = useState([]);
  const notificationRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      const fetchNotifications = async () => {
        try {
          const response = await axios.get(`https://www.upholic.in/Upholic_Api/notifications.php?user_id=${userId}`);
          const data = Array.isArray(response.data) ? response.data : [];
          setNotifications(data);
        } catch (error) {
          console.error('Error fetching notifications:', error);
          setNotifications([]);
        }
      };

      fetchNotifications();
    }
  }, [isVisible, userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    const triggerNotificationInsertion = async () => {
      try {
        await axios.post('https://www.upholic.in/Upholic_Api/insert_notifications.php', { user_id: userId });
      } catch (error) {
        console.error('Error inserting notifications:', error);
      }
    };

    if (isVisible) {
      triggerNotificationInsertion();
    }
  }, [isVisible, userId]);

  const clearNotifications = async () => {
    try {
      const response = await axios.post('https://www.upholic.in/Upholic_Api/mark_notifications_as_read.php', { user_id: userId });
      // console.log('Notifications marked as read successfully.', response.data);

      if (response.data.success) {
        setNotifications([]);
      } else {
        console.error('Failed to mark notifications as read:', response.data.error);
      }
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  if (!isVisible) return null;

  return (
    <>
      <div className="overlay" onClick={onClose}></div>
      <div className="notification-menu" ref={notificationRef}>
        <div className="notification-header">
          <h3>Notifications</h3>
          <button onClick={onClose}>Close</button>
        </div>
        <div className="notification-content">
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div key={index} className="notification-item">
                {notification.message}
              </div>
            ))
          ) : (
            <p>No new notifications</p>
          )}
        </div>
        {notifications.length > 0 && (
          <button className="clear-button" onClick={clearNotifications}>
            Clear All
          </button>
        )}
      </div>
    </>
  );
};

export default Notification;
