import React, { useState, useEffect, useRef } from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import home_insurance from '../../assets/images/home_insurance.avif';
import structural_Cover from '../../assets/images/Structural_cover.avif';
import Home_content_cover from '../../assets/images/Home_content_cover.webp';
import Fire_cover from '../../assets/images/Fire_cover.avif';
import Public_liability_cover from '../../assets/images/Public_liability_cover.avif';
import Theft_cover from '../../assets/images/Theft_cover.avif';
import Landlord_cover from '../../assets/images/Landlord_cover.jpg';
import Tenant_cover from '../../assets/images/Tenant_cover.avif';
import './Home_Insurance.css';
import { Link } from 'react-router-dom';
import { ImCross } from 'react-icons/im';

function Home_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        { img: structural_Cover, title: 'Structural Cover', description: 'Provides coverage for the physical structure of your home against various risks like fire and storms.' },
        { img: Home_content_cover, title: 'Home Content Cover', description: 'Protects your belongings, including furniture, electronics, and personal items from damage or theft.' },
        { img: Fire_cover, title: 'Fire Cover', description: 'Covers damage to your home caused by fire, ensuring you are financially protected in case of such incidents.' },
        { img: Public_liability_cover, title: 'Public Liability Cover', description: 'Provides coverage if someone is injured on your property, protecting you from legal liabilities.' },
        { img: Theft_cover, title: 'Theft Cover', description: 'Offers protection against the loss of personal belongings due to theft or burglary.' },
        { img: Landlord_cover, title: "Landlord's Cover", description: 'Tailored for landlords, this cover protects both the property and rental income loss.' },
        { img: Tenant_cover, title: "Tenant's Cover", description: 'Offers coverage for tenants, including contents insurance and liability for damage caused to the property.' }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);


    return (
        <>
            <div className="home_insurance-header-section">
                <Link to="/life-Insurance" className="login-close-link">
                    <ImCross className="login-close-icon" />
                </Link>
                <h1 className="home_insurance-main-title">Home Insurance</h1>
                <p className="home_insurance-subtitle">Protect Your Home and Belongings with Comprehensive Coverage</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        Home insurance provides financial protection against damage to your property and personal belongings. It covers a wide range of risks, from fire and theft to natural disasters.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Home insurance is designed to be affordable while providing comprehensive coverage for your home and contents.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Clear terms with no complex clauses, making it easy for homeowners to understand their coverage.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Choose from various plans and add-ons to customize your home insurance policy according to your needs.</p>
                        </div>
                    </div>
                </div>

                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Home Insurance</h2>
                   <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <Link
                                    to="/book-free-appointment"  // Link to the desired page
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="insurance-cta-container">
                    <div className="insurance-cta-image-container">
                        <img
                            src={home_insurance}  // Replace with the image for home insurance
                            alt="Home insurance illustration"
                            className="insurance-cta-image"
                        />
                    </div>
                    <div className="insurance-cta-content">
                        <h1 className="insurance-cta-title">Why Choose Home Insurance?</h1>
                        <p className="insurance-cta-description">
                            Home insurance is essential for protecting your home and belongings from unexpected events like fire, theft, or natural disasters. It provides financial coverage for damage to your property and personal items, helping you rebuild and recover. Whether you own or rent, home insurance offers peace of mind and ensures that your living space remains secure.
                        </p>
                        <ul className="insurance-cta-benefits">
                            <li><strong>Property Damage:</strong> Covers damages from fire, floods, earthquakes, and other disasters.</li>
                            <li><strong>Theft Protection:</strong> Reimburses you for stolen or damaged personal belongings.</li>
                            <li><strong>Liability Protection:</strong> Covers legal and medical costs if someone is injured on your property.</li>
                            <li><strong>Natural Disaster Coverage:</strong> Includes protection for damages from hurricanes, storms, or earthquakes.</li>
                            <li><strong>Loss of Use:</strong> Helps cover living expenses if your home is uninhabitable due to a covered event.</li>
                            <li><strong>Peace of Mind:</strong> Financial protection for your home and assets during unexpected events.</li>
                        </ul>

                        <p className="insurance-cta-footer">
                            Protect your home and belongings—get home insurance today.
                        </p>

                        <Link to="/Signup">
                            <button className="insurance-cta-button">Get Your Home Insurance</button>
                        </Link>
                    </div>
                </div>


                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title">Benefits of Home Insurance</h2>
                    <div className='home_insurance-benefits-inner-section'>
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Home insurance offers peace of mind, knowing your property and belongings are protected against a variety of risks.</li>
                                <li>It ensures financial protection in the event of disasters like fire, storms, or theft, covering the costs of repairs or replacement.</li>
                                <li>Home insurance can provide liability coverage if someone is injured on your property, protecting you from potential lawsuits.</li>
                                <li>Many policies offer customizable add-ons, allowing you to tailor your coverage to meet your specific needs.</li>
                                <li>Depending on your region, home insurance may provide additional benefits like coverage for personal accidents and legal expenses.</li>
                                <li>For more benefits, Book a free appointment with us.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                        <div>
                            <img src={home_insurance} alt="Benefits of Home Insurance" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home_Insurance;
