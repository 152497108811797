import React from 'react'
import About_Us from '../about-us/About_Us'
import About_Us_Ritaxes from '../about_us_ritaxes/About_Us_Ritaxes'
import About_Mission from '../about_mission/About_Mission'
import Footer from '../../pages/footer/Footer'
import Copyright from '../../pages/copyright/Copyright'
import About_Growth from '../about_growth/About_Growth'
import About_Development from '../about_dev/About_Development'

function Our_Story() {
  return (
    <>
    <About_Us/>
    <About_Us_Ritaxes/>
    <About_Development/>
    <About_Mission/>
    <About_Growth/>
    <Footer/>
    <Copyright/>
    </>
  )
}

export default Our_Story