import React, {useState} from "react";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import Insurance_entry from "../admin_insurance_entry/Insurance_entry";
import Insurance_nominee from "../admin_insurance_nominee/Insurance_nominee";
import Life_Insurance_kyc from "../admin_insurance_kyc/Life_Insurance_kyc";
import Pms_login_data from "../pms_login_entry/Pms_login_data";

function Life_insurance_home() {
      const [accordionOpen, setAccordionOpen] = useState(false);
    
      const toggleAccordion = () => {
        // console.log("Accordion toggled"); // Add this line to verify the function is called
        setAccordionOpen(!accordionOpen);
      };
  return (
    <>
    <Admin_sidebar_links/>
      <div className="pms_dashboard_area">
        {/* UL List */}
        <ul className="pms_dashboard_circles">
          {Array(10)
            .fill()
            .map((_, index) => (
              <li key={index}></li>
            ))}
        </ul>
        {/* Accordion Card */}
        <div className="Pms_dashboard_card">
          <div
            className="Pms_dashboard_section_title"
            onClick={toggleAccordion}
          >
            LI Data Entry
          </div>
          {accordionOpen && (
            <div className="accordion-content">
                <Pms_login_data/>
                <Life_Insurance_kyc/>
                <Insurance_nominee/>
                <Insurance_entry/>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Life_insurance_home;
