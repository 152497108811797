import React, { useState, useEffect } from "react";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import adminlogoo from "../../assets/images/upholic.newlogo.png";
import axios from "axios";
import "./HR_dashboard.css";

function HR_dashboard() {
  const [employeeAccordionOpen, setEmployeeAccordionOpen] = useState(false); // State for employee details accordion
  const [kycAccordionOpen, setKycAccordionOpen] = useState(false); // State for employee KYC accordion
  const [employeeKYCData, setEmployeeKYCData] = useState([]);
  const [employeeKYCAccordionOpen, setEmployeeKYCAccordionOpen] = useState(false);

  // Initial state for employee data and resume data
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeStats, setEmployeeStats] = useState({
    totalEmployees: 0,
    maleCount: 0,
    femaleCount: 0,
  });

  // Fetch employee data
  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/fetch_employee_details.php" // Adjust the API path accordingly
      );
      const employees = response.data;

      // Calculate total, male, and female counts
      const totalEmployees = employees.length;
      const maleCount = employees.filter((emp) => emp.gender === "Male").length;
      const femaleCount = employees.filter((emp) => emp.gender === "Female").length;

      setEmployeeData(employees); // Set full employee data
      setEmployeeStats({
        totalEmployees,
        maleCount,
        femaleCount,
      });
    } catch (error) {
      console.error("There was an error fetching employee data!", error);
    }
  };

  // Fetch employee KYC data
  const fetchEmployeeKYCData = async () => {
    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/fetch_kyc_resume.php"
      );
      if (response.data && !response.data.error) {
        setEmployeeKYCData(response.data);
      } else {
        // console.log("Error fetching employee KYC data:", response.data.error);
      }
    } catch (error) {
      console.error("There was an error fetching employee KYC data:", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchEmployeeData();
    fetchEmployeeKYCData();
  }, []);

  const toggleEmployeeAccordion = () => {
    setEmployeeAccordionOpen((prevState) => !prevState);
  };

  const toggleEmployeeKYCAccordion = () => {
    setEmployeeKYCAccordionOpen((prevState) => !prevState);
  };

  return (
    <>
      <Admin_sidebar_links />
      <div className="man-man-div">
        <div className="deposite-header area">
          <ul className="circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="deposite-container">
            <div className="deposite-logo-img">
              <img
                src={adminlogoo}
                alt="Logo 1"
                className="deposite-image-logo"
              />
            </div>
            <div className="deposite-search-bar">
              <input type="text" placeholder="Search here" />
              <ion-icon name="search" className="search-icon"></ion-icon>
            </div>
            <div className="deposite-logo2-bar">
              <h1 className="deposite-page-headingg">Employee</h1>
            </div>
          </div>

          {/* ======================= Cards ================== */}
          <div className="deposite-cardBox">
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{employeeStats.totalEmployees}</div>
                <div className="deposite-cardName">Total Employees</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{employeeStats.maleCount}</div>
                <div className="deposite-cardName">Male</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="man-outline"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{employeeStats.femaleCount}</div>
                <div className="deposite-cardName">Female</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="woman-outline"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        {/* Employee Details Section */}
        <div className="pms_dashboard_areaa">
          <ul className="pms_dashboard_circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="Pms_dashboard_card">
            <div
              className="Pms_dashboard_section_title"
              onClick={toggleEmployeeAccordion}
              style={{ cursor: "pointer" }}
            >
              Employee Details
            </div>
            {employeeAccordionOpen && (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th className="custom-table-header">Employee ID</th>
                    <th className="custom-table-header">First Name</th>
                    <th className="custom-table-header">Last Name</th>
                    <th className="custom-table-header">Date Of Birth</th>
                    <th className="custom-table-header">Designation</th>
                    <th className="custom-table-header">Phone Number</th>
                    <th className="custom-table-header">Employee Type</th>
                    <th className="custom-table-header">Gender</th>
                    <th className="custom-table-header">Blood Group</th>
                    <th className="custom-table-header">Department</th>
                    <th className="custom-table-header">Office</th>
                    <th className="custom-table-header">Employee Status</th>
                    <th className="custom-table-header">Joining Date</th>
                    <th className="custom-table-header">Last Date</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeData.length > 0 ? (
                    employeeData.map((employee, index) => (
                      <tr key={index}>
                        <td>{employee.employee_id}</td>
                        <td>{employee.first_name}</td>
                        <td>{employee.last_name}</td>
                        <td>{employee.dob}</td>
                        <td>{employee.designation}</td>
                        <td>{employee.phone_number}</td>
                        <td>{employee.employee_type}</td>
                        <td>{employee.gender}</td>
                        <td>{employee.blood_group}</td>
                        <td>{employee.department}</td>
                        <td>{employee.office}</td>
                        <td>{employee.status}</td>
                        <td>{employee.joining_date}</td>
                        <td>{employee.last_date}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="13">No employee data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>


        {/* Employee KYC Section */}
        <div className="pms_dashboard_areaa">
          <ul className="pms_dashboard_circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="Pms_dashboard_card">
            <div
              className="Pms_dashboard_section_title"
              onClick={toggleEmployeeKYCAccordion}
              style={{ cursor: "pointer" }}
            >
              Employee KYC Details
            </div>
            {employeeKYCAccordionOpen && (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th className="custom-table-header">Employee ID</th>
                    <th className="custom-table-header">Profile Photo</th>
                    <th className="custom-table-header">Aadhar Card</th>
                    <th className="custom-table-header">Pan Card</th>
                    <th className="custom-table-header">Education Certificate</th>
                    <th className="custom-table-header">Bank Cheque</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeKYCData.length > 0 ? (
                    employeeKYCData.map((employeeKYC, index) => (
                      <tr key={index}>
                        <td>{employeeKYC.employee_id}</td>
                        <td>{employeeKYC.photo}</td>
                        <td>{employeeKYC.aadhar_card}</td>
                        <td>{employeeKYC.pan_card}</td>
                        <td>{employeeKYC.education_certificate}</td>
                        <td>{employeeKYC.bank_check}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No employee KYC data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HR_dashboard;
