import React, { useState } from "react";
import axios from "axios";
import "./Document_insert_resume.css";

function Document_insert_resume() {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // For success messages
  const [message, setMessage] = useState(""); // For error messages
  const [isExistingData, setIsExistingData] = useState(false); // Track if the phone number is already in the DB
  // const [files, setFiles] = useState({
  //   photo: null,
  // });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    bloodgroup: "",
    maritalStatus: "",
    email: "",
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    education: "",
    project: "",
    skill: "",
    reason: "",
    resume: null,
  });

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Validation rules
    const onlyWordsRegex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
    const onlyNumbersRegex = /^\d*$/; // Allow only digits

    // Apply validation
    if (
      ([
        "firstName",
        "lastName",
        "city",
        "education",
        "project",
        "skill",
      ].includes(name) &&
        !onlyWordsRegex.test(value)) ||
      (name === "phoneNumber" && !onlyNumbersRegex.test(value))
    ) {
      setMessage("Invalid input! Please enter valid data.");
      return; // Prevent invalid input
    } else {
      setMessage(""); // Clear error message on valid input
    }

    // Ensure phone number has exactly 10 digits when completing input
    if (name === "phoneNumber" && value.length === 10) {
      try {
        const response = await axios.get(
          `https://www.upholic.in/Upholic_Api/insert_resume.php?phoneNumber=${value}`
        );
        if (response.data.success && response.data.data) {
          const fetchedData = response.data.data;
          setFormData((prevData) => ({
            ...prevData,
            firstName: fetchedData.first_name,
            lastName: fetchedData.last_name,
            gender: fetchedData.gender,
            dob: fetchedData.dob,
            bloodgroup: fetchedData.bloodgroup,
            maritalStatus: fetchedData.marital_status,
            email: fetchedData.email,
            address: fetchedData.address,
            country: fetchedData.country,
            state: fetchedData.state,
            city: fetchedData.city,
            zip: fetchedData.zip,
            education: fetchedData.education,
            project: fetchedData.project,
            skill: fetchedData.skill,
            reason: fetchedData.reason,
            resume: fetchedData.resume,
          }));
          setIsExistingData(true); // Mark as existing data
          setSuccessMessage("Data fetched successfully!");
        } else {
          setMessage(response.data.message); // No data found for this phone number
          setIsExistingData(false); // Mark as new data
          setFormData((prevData) => ({
            ...prevData,
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            bloodgroup: "",
            maritalStatus: "",
            email: "",
            address: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            education: "",
            project: "",
            skill: "",
            reason: "",
            resume: null,
          }));
          setSuccessMessage("");
        }
      } catch (error) {
        setMessage("Error fetching data.");
        setIsExistingData(false);
        setSuccessMessage("");
      }
    }

    // Enforce maximum length in JavaScript
    if (name === "phoneNumber" && value.length > 10) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.slice(0, 10), // Trim input to 10 digits
      }));
      setMessage("Phone number cannot exceed 10 digits.");
      return;
    }

    // Update form data normally for other fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resumeUrl =
    formData?.resume && typeof formData.resume === "string"
      ? `https://www.upholic.in/Upholic_Api/uploads/resumes/${formData.resume.replace(
          /^http:\/\/localhost\/Upholic_Api\/uploads\//,
          ""
        )}`
      : formData?.resume
      ? URL.createObjectURL(formData.resume) // For files, create a local URL
      : null;

      // console.log("hii: " + resumeUrl);


  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      resume: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (let key in formData) {
      form.append(key, formData[key]);
    }
    // Check if we should update or insert
    const action = isExistingData ? "update" : "insert";
    form.append("action", action); // Add action to the FormData

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insert_resume.php",
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log("Response:", response.data);
      setMessage(""); // Clear the message before setting the success message

      if (response.data.success) {
        setSuccessMessage(
          action === "update"
            ? "Data updated successfully!"
            : "Data inserted successfully!"
        );
        // Reset form data to default values after successful insert
        if (action === "insert") {
          setFormData({
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            bloodgroup: "",
            maritalStatus: "",
            email: "",
            phoneNumber: "",
            address: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            education: "",
            project: "",
            skill: "",
            reason: "",
            resume: null,
          });
          setIsExistingData(false); // Mark as new data
        }
      } else {
        setMessage(response.data.message); // Show error message from backend
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccessMessage(""); // Clear success message if error occurs
      setMessage("Error submitting form."); // Display error message
    }
  };

  const fileUrl = (fileName) => {
    // console.log("helloo"+fileName);  // Logs the value of fileName
    return fileName
      ? `https://www.upholic.in/Upholic_Api/uploads/resumes/${fileName}`
      : null;
  };
  

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            Resume Upload
          </div>
          {accordionOpen && (
            <>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {message && <div className="error-message">{message}</div>}
              {resumeUrl && (
                <div className="resume-image">
                  <img src={resumeUrl} alt="Uploaded Resume" />
                </div>
              )}

              <form
                onSubmit={handleSubmit}
                className="Pms_insert_user_kyc-form"
              >
                <div className="Pms_insert_user_kyc-row">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Gender:</label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Date of Birth:</label>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Blood Group:</label>
                  <select
                    name="bloodgroup"
                    value={formData.bloodgroup}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Marital Status:</label>
                  <select
                    name="maritalStatus"
                    value={formData.maritalStatus}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="widowed">Widowed</option>
                    <option value="separated">Separated</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    maxLength="10" // Enforce a maximum length of 10 digits
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Country:</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cabo Verde">Cabo Verde</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo (Congo-Brazzaville)">
                      Congo (Congo-Brazzaville)
                    </option>
                    <option value="Congo (Congo-Kinshasa)">
                      Congo (Congo-Kinshasa)
                    </option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Greece">Greece</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea (North)">Korea (North)</option>
                    <option value="Korea (South)">Korea (South)</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar (Burma)">Myanmar (Burma)</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="North Macedonia">North Macedonia</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Vincent and the Grenadines">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City">Vatican City</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>State:</label>
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>City:</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Zip:</label>
                  <input
                    type="text"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Education:</label>
                  <input
                    type="text"
                    name="education"
                    value={formData.education}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Project:</label>
                  <input
                    type="text"
                    name="project"
                    value={formData.project}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Skill:</label>
                  <input
                    type="text"
                    name="skill"
                    value={formData.skill}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Resume Upload:</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="file"
                      name="resume"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(e) => handleFileChange(e, "resume")}
                    />
                    {formData.resume ? (
                      typeof formData.resume === "string" ? (
                        <a
                          href={fileUrl(formData.resume)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontSize: "0.9rem", color: "blue" }}
                        >
                          {formData.resume}
                        </a>
                      ) : (
                        <span style={{ fontSize: "0.9rem", color: "green" }}>
                          File ready for upload
                        </span>
                      )
                    ) : (
                      <span style={{ fontSize: "0.9rem", color: "gray" }}>
                        No file uploaded
                      </span>
                    )}
                  </div>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Reason for Reject/Select</label>
                  <select
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select Reason--</option>
                    <option value="Incomplete Information">
                      Incomplete Information
                    </option>
                    <option value="Not Qualified">Not Qualified</option>
                    <option value="Overqualified">Overqualified</option>
                    <option value="Duplicate Submission">
                      Duplicate Submission
                    </option>
                    <option value="Selected">Selected</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Other">Other</option>{" "}
                    {/* Additional 'Other' option */}
                  </select>
                </div>

                <div className="investment-row">
                  <button type="submit" className="edit-button">
                    {isExistingData ? "Update" : "Insert"}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Document_insert_resume;
