import React, { useEffect, useState } from "react";
import axios from "axios";
import "chart.js/auto";
import "./Admin_Deposite.css";
import adminlogoo from "../../assets/images/upholic.newlogo.png";
import Deposite_chart from "../deposite_chart/Deposite_chart";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";

function Admin_Deposite() {
  const [depositeData, setDepositeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [totalMonthlyInterest, setTotalMonthlyInterest] = useState(0);
  const [totalYearlyInterest, setTotalYearlyInterest] = useState(0);
  const [averageRate, setAverageRate] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  // Set current month and year
  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.toLocaleString("default", { month: "long" });
    const year = currentDate.getFullYear();

    setSelectedMonth(month);
    setSelectedYear(year);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch deposits data
        const depositeRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/deposite.php?action=deposite`
        );
        // console.log("Deposite Data Response:", depositeRes.data);
        setDepositeData(
          Array.isArray(depositeRes.data)
            ? depositeRes.data
            : depositeRes.data.deposits || []
        );

        // Fetch total amount
        const totalAmountRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/totaldepositeamount.php?action=total_amount`
        );
        // console.log("Total Amount Response:", totalAmountRes.data);
        setTotalAmount(totalAmountRes.data.total_amount);

        // Fetch user count
        const userCountRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/totaluserdeposite.php?action=count_users`
        );
        // console.log("User Count Response:", userCountRes.data);
        setUserCount(userCountRes.data.user_count);

        // Fetch total monthly interest
        const totalMonthlyInterestRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/totalimtdeposite.php?action=total_monthly_interest`
        );
        // console.log("Total Monthly Interest Response:", totalMonthlyInterestRes.data);
        setTotalMonthlyInterest(totalMonthlyInterestRes.data.total_monthly_interest);

        // Fetch total quarterly payment
        const fetchTotalYearlyInterest = await axios.get(
          `https://www.upholic.in/Upholic_Api/totaliytddeposite.php?action=total_yearly_interest`
        );
        // console.log("Total Quarterly Payment Response:", fetchTotalYearlyInterest.data);
        setTotalYearlyInterest(Math.floor(fetchTotalYearlyInterest.data.total_yearly_interest));

        // Fetch average rate (calculated rate percentage)
        const averageRateRes = await axios.get(
          `https://www.upholic.in/Upholic_Api/totalratedeposite.php?action=calculate_rate`
        );
        // console.log("Average Rate Response:", averageRateRes.data);
        setAverageRate(averageRateRes.data.calculated_rate_percentage || 0);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data: " + error.message);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // Handle file upload
  const handleUpload = async (e, deposit_id, selectedMonth, selectedYear) => {
    const row = e.target.closest("tr"); // Get the closest row to the button clicked
    const fileInput = row.querySelector(`input[name="payment_document"]`); // Find file input within the row
    const file = fileInput?.files[0]; // Get selected file

    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("action", "upload_document");
    formData.append("deposit_id", deposit_id);
    formData.append("month", selectedMonth);
    formData.append("year", selectedYear);
    formData.append("payment_document", file);

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/deposite_upload_interest_doc.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        alert("Document uploaded successfully!");
      } else {
        alert("Failed to upload the document.");
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("Error uploading document.");
    }
  };


  // Handle download
  const handleDownload = async (depositId, month, year) => {
    try {
      const url = `https://www.upholic.in/Upholic_Api/download_deposit_interest_doc.php?deposit_id=${depositId}&month=${month}&year=${year}`;
      // console.log('Requesting document download from URL:', url); // Log the requested URL

      const response = await axios.get(url, {
        params: { deposit_id: depositId, month: month, year: year },
        responseType: 'blob', // Ensuring response is treated as a Blob (binary data)
      });

      // console.log('Document downloaded successfully:', response);

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data); // Create an object URL for the blob
      link.download = 'downloaded_document'; // Set a default name for the downloaded file
      link.click(); // Simulate a click to trigger the download
    } catch (error) {
      console.error('Error downloading document:', error);
      console.error('Error details:', error.response ? error.response.data : error.message); // Log the error details
    }
  };

  // Function to filter data based on search query
  const filteredData = depositeData.filter((deposite) =>
    Object.values(deposite).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Handle month selection change
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  // Handle year selection change
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  // Handle file change for payment document
  const handleFileChange = (e) => {
    // Add your file handling logic here
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Admin_sidebar_links />
      <div className="man-man-div">
        <div className="deposite-header area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="deposite-container">
            <div className="deposite-logo-img">
              <img
                src={adminlogoo}
                alt="Logo 1"
                className="deposite-image-logo"
              />
            </div>
            <div className="deposite-search-bar">
              <input type="text" placeholder="Search here" />
              <ion-icon name="search" className="search-icon"></ion-icon>
            </div>
            <div className="deposite-logo2-bar">
              <h1 className="deposite-page-heading">Deposite</h1>
            </div>
          </div>

          {/* ======================= Cards ==================  */}
          <div className="deposite-cardBox">
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{userCount}</div>
                <div className="deposite-cardName">Total Customer</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">₹{totalAmount}</div>
                <div className="deposite-cardName">Deposit Amount</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{averageRate}%</div>
                <div className="deposite-cardName">Rate</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="stats-chart-outline"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">₹{totalMonthlyInterest}</div>
                <div className="deposite-cardName">IMTD</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="wallet"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">₹{totalYearlyInterest}</div>
                <div className="deposite-cardName">IYTD</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="wallet"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <Deposite_chart />

        {/* ======================= Deposit Data Table ================== */}
        <div className="trading_dashboard_area">
          <div className="trading_dashboard_card">
            <div className="trading-dashboard-header">
              <h2 className="deposit-page-heading">Deposits Customer</h2>
              <div className="trading-deposit-search-container">
                <input
                  type="text"
                  className="trading-deposit-search-input"
                  placeholder="Search deposits..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <div className="trading-table-container">
              <table className="trading-table">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>D / O / I</th>
                    <th>User-ID</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Withdrawal</th>
                    <th>Amount Received</th>
                    <th>Final Amount</th>
                    <th>Rate</th>
                    <th>Monthly Interest</th>
                    <th>Quarterly Interest</th>
                    <th>Yearly Interest</th>
                    <th>Deposit ID</th>
                    <th>Payment Document</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((deposite) => (
                      <tr key={deposite.id}>
                        <td>{deposite.id}</td>
                        <td>{deposite.date_of_investment}</td>
                        <td>{deposite.user_id}</td>
                        <td>{deposite.name}</td>
                        <td>{deposite.amount_invested}</td>
                        <td>{deposite.withdrawal}</td>
                        <td>{deposite.received_amount}</td>
                        <td>{deposite.final_amount}</td>
                        <td>{deposite.rate}</td>
                        <td>{deposite.monthly_interest}</td>
                        <td>{deposite.quarterly_interest}</td>
                        <td>{deposite.yearly_interest}</td>
                        <td>{deposite.deposit_id}</td>
                        <td>
                          {selectedMonth && selectedYear && (
                            <>
                              <select value={selectedMonth} onChange={handleMonthChange}>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </select>
                              <select value={selectedYear} onChange={handleYearChange}>
                                <option value={selectedYear}>{selectedYear}</option>
                                <option value="2024">2024</option>
                              </select>
                              <input
                                type="file"
                                name="payment_document"
                                accept=".pdf,.doc,.jpg,.png"
                                onChange={handleFileChange}
                              />
                              <button
                                className="upload-btn"
                                onClick={(e) => handleUpload(e, deposite.deposit_id, selectedMonth, selectedYear)}
                              >
                                Upload Document
                              </button>


                              {/* Download Button */}
                              <button
                                className="download-btn"
                                onClick={() => handleDownload(deposite.deposit_id, selectedMonth, selectedYear)}
                              >
                                Download Document
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="14">No deposit data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin_Deposite;
