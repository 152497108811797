import React from 'react';
import './Individual_Health_Info.css';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';

function Individual_Health_Info() {
    return (
        <>
        <Navbar/>
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Individual Health Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Individual Health Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            // src={IndividualHealthImg}
                            alt="Individual Health Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Individual Health Insurance is a policy that provides health coverage to a single person or a family. It offers financial protection against medical expenses, including hospitalization, doctor visits, prescription medications, and preventive care. It is ideal for self-employed individuals, freelancers, or anyone not covered by an employer-sponsored plan.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Individual Health Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Customizable Coverage:</strong> Choose a plan that fits your specific healthcare needs and budget.</li>
                            <li><strong>✅ Portability:</strong> The insurance stays with you even if you change jobs or locations.</li>
                            <li><strong>✅ Access to Networks:</strong> Access to a wide network of doctors, hospitals, and specialists.</li>
                            <li><strong>✅ Preventive Care:</strong> Coverage for vaccinations, screenings, and annual check-ups.</li>
                            <li><strong>✅ Financial Protection:</strong> Reduces out-of-pocket expenses for medical emergencies or chronic conditions.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Individual Health Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            // src={BenefitsImg}
                            alt="Benefits of Individual Health Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For Individuals:</strong></p>
                            <ul>
                                <li>Personalized Coverage: Tailor your plan to meet your unique healthcare needs.</li>
                                <li>Peace of Mind: Protects you from unexpected medical expenses.</li>
                                <li>Preventive Care: Encourages regular check-ups and early detection of health issues.</li>
                                <li>Tax Benefits: Premiums may be tax-deductible, and HSAs offer additional tax advantages.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Individual Health Insurance */}
                <section className="Individual-Health-section">
                    <h2>Types of Individual Health Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Health Maintenance Organization (HMO):</strong> Requires a primary care physician and referrals for specialists. Offers lower premiums but limited network flexibility.</li>
                            <li><strong>2️⃣ Preferred Provider Organization (PPO):</strong> Provides flexibility to see specialists without referrals and allows out-of-network care at a higher cost.</li>
                            <li><strong>3️⃣ Exclusive Provider Organization (EPO):</strong> Combines features of HMOs and PPOs but does not cover out-of-network care except in emergencies.</li>
                            <li><strong>4️⃣ High-Deductible Health Plan (HDHP):</strong> Features lower premiums and higher deductibles, often paired with a Health Savings Account (HSA).</li>
                            <li><strong>5️⃣ Catastrophic Plans:</strong> Designed for young, healthy individuals, offering low premiums and high deductibles, covering essential health benefits after the deductible is met.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Individual Health Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Individual Health Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            // src={WhoShouldOptImg}
                            alt="Who Should Opt for Individual Health Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Self-employed individuals or freelancers.</li>
                                <li>✔ Part-time workers without employer-sponsored coverage.</li>
                                <li>✔ Families looking for comprehensive health coverage.</li>
                                <li>✔ Individuals seeking portability and flexibility in their health plans.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                </div>
            </div>
            <Footer/>
            <Copyright/>
        </>
    )
}

export default Individual_Health_Info