import React from 'react';
import './About_Growth.css';
import about_us_mission from '../../assets/images/Growth_fin_img.jpg';
import { Link } from 'react-router-dom';

function About_Growth() {
    return (
        <>
            <div className="Growth-main-container">
                {/* Top Section */}
                <div className="Growth-top-section">
                    <div className="Growth-image-container">
                        <img
                            src={about_us_mission}
                            alt="Financial Planning"
                            className="Growth-main-image"
                        />
                    </div>
                    <div className="Growth-text-container">
                        <h2>We're Here to Help You Achieve Your Financial Goals</h2>
                        <p>
                            At Upholic, we offer comprehensive services in life insurance, financial planning, portfolio management, and loans, helping you secure a better future with personalized solutions.
                        </p>
                        <Link to="/book-free-appointment"><button className="Growth-contact-button">Contact Us</button></Link>
                    </div>
                </div>

                {/* Feature Boxes */}
                <div className="Growth-feature-section">
                    <div className="Growth-feature-box">
                        <span className="Growth-icon">💼</span>
                        <h3>Life Insurance</h3>
                        <p>
                            Our life insurance services are designed to provide financial security for you and your loved ones, offering a range of customizable plans to suit your needs.
                        </p>
                    </div>
                    <div className="Growth-feature-box">
                        <span className="Growth-icon">📊</span>
                        <h3>Financial Planning</h3>
                        <p>
                            We provide expert financial planning services to help you manage your finances, save for retirement, and make informed investment decisions.
                        </p>
                    </div>
                    <div className="Growth-feature-box">
                        <span className="Growth-icon">📈</span>
                        <h3>Portfolio Management (PMS)</h3>
                        <p>
                            Our Portfolio Management Services (PMS) are tailored to help you grow your wealth with a personalized strategy that matches your financial goals and risk profile.
                        </p>
                    </div>
                    <div className="Growth-feature-box">
                        <span className="Growth-icon">💰</span>
                        <h3>Loan Solutions</h3>
                        <p>
                            We offer competitive loan solutions to meet your personal and business needs, including home loans, personal loans, and more.
                        </p>
                    </div>
                </div>

                {/* Stats Section */}
                <div className="Growth-stats-section">
                    <div className="Growth-stat-box">
                        <h3>100+</h3>
                        <p>Satisfied Clients</p>
                    </div>
                    <div className="Growth-stat-box">
                        <h3>100+</h3>
                        <p>Successful Financial Plans</p>
                    </div>
                    <div className="Growth-stat-box">
                        <h3>4.8</h3>
                        <p>Client Rating</p>
                    </div>
                    <div className="Growth-stat-box">
                        <h3>2+</h3>
                        <p>Offices Nationwide</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About_Growth;