import React, { useState } from 'react';
import Admin_sidebar_links from '../admin_sidebar_links/Admin_sidebar_links';
import Third_Party_Data_Entry from '../admin_third_party_data_entry/Third_Party_Data_Entry';

function Third_Party_Entry() {
    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        // console.log("Accordion toggled"); // Add this line to verify the function is called
        setAccordionOpen(!accordionOpen);
    };

    return (
        <>
            <Admin_sidebar_links />
            <div className="pms_dashboard_area">
                {/* UL List */}
                <ul className="pms_dashboard_circles">
                    {Array(10)
                        .fill()
                        .map((_, index) => (
                            <li key={index}></li>
                        ))}
                </ul>
                {/* Accordion Card */}
                <div className="Pms_dashboard_card">
                    <div
                        className="Pms_dashboard_section_title"
                        onClick={toggleAccordion}
                    >
                        Third Party Entry
                    </div>
                    {accordionOpen && (
                        <div className="accordion-content">
                            <Third_Party_Data_Entry />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Third_Party_Entry;
