import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import Logo from "../../assets/images/Upholic_Logo.png";
import Popup from "../popup/Popup";

function Navbar() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg fixed-top ${isPopupOpen ? "navbar-behind-popup" : ""
          }`}
      >
        <div className="container-fluid">
          <Link className="navbar-brand me-auto" to="/">
            <img src={Logo} alt="Upholic Logo" className="navbar-logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <Link className="navbar-brand me-auto" to="/">
                <img src={Logo} alt="Upholic Logo" className="navbar-logo" />
              </Link>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link ex-lg-2"
                    to="/popup"
                    onClick={togglePopup}
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link ex-lg-2" to="/about">
                    About
                  </Link>
                </li>

              </ul>
              <div className="d-flex justify-content-center mt-3">
                <Link to="/Signup" className="login-button mr-2">
                  Sign-Up
                </Link>
                <Link to="/login" className="login-button">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section className="hero-section"></section>
      {isPopupOpen && <Popup />}
    </>
  );
}

export default Navbar;