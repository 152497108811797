import React from "react";
import li_home from "../../assets/images/about_li_img.jpg";
import fd_home from "../../assets/images/about_fd_img.jpg";
import PMS_home from "../../assets/images/about_pms_img.jpg";
import deposite_home from "../../assets/images/about_deposit_img.jpg";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "./Popup.scss";

function Popup() {
  return (
    <>
      <section className="service-popup-section">
        <Link to="/" className="login-close-link">
          <ImCross className="login-close-icon" />
        </Link>
        <h2 className="service-popup-title">Our Services</h2>
        <p className="service-popup-subtitle">CHOOSE A CATEGORY</p>
        <div className="service-popup-container">
          <div className="service-popup-card">
            <Link to="/life-Insurance" className="service-popup-card-inner">
              <div className="service-popup-box">
                <div className="service-popup-img-box">
                  <img className="service-popup-img" src={li_home} alt="Life Insurance" />
                </div>
                <div className="service-popup-icon">
                  <h3 className="service-popup-heading">Life Insurance</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="service-popup-card">
            <Link to="/commingsoon" className="service-popup-card-inner">
              <div className="service-popup-box">
                <div className="service-popup-img-box">
                  <img
                    className="service-popup-img"
                    src={fd_home}
                    alt="Mutual Funds"
                  />
                </div>
                <div className="service-popup-icon">
                  <h3 className="service-popup-heading">Mutual Funds</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="service-popup-card">
            <Link to="/commingsoon" className="service-popup-card-inner">
              <div className="service-popup-box">
                <div className="service-popup-img-box">
                  <img className="service-popup-img" src={PMS_home} alt="PMS" />
                </div>
                <div className="service-popup-icon">
                  <h3 className="service-popup-heading">PMS</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="service-popup-card">
            <Link to="/commingsoon" className="service-popup-card-inner">
              <div className="service-popup-box">
                <div className="service-popup-img-box">
                  <img className="service-popup-img" src={deposite_home} alt="Loan" />
                </div>
                <div className="service-popup-icon">
                  <h3 className="service-popup-heading">Loan</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Popup;
