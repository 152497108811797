import React, { useState } from 'react';
import axios from 'axios';
import Admin_sidebar_links from '../admin_sidebar_links/Admin_sidebar_links';

function Third_Party_Data_Entry() {
    const [formData, setFormData] = useState({
        customerID: '',
        companyName: '',
        customerName: '',
        phoneNumber: '',
        investmentDate: '',
        amount: '',
        percentage: '',
        commission: ''
    });

    const [accordionOpen, setAccordionOpen] = useState(false);
    const [isExistingData, setIsExistingData] = useState(false); // This could be true if you're editing an existing entry

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        // Update the formData state
        setFormData((prevData) => {
            const newFormData = { ...prevData, [name]: value };

            // Automatically calculate commission when percentage or amount changes
            if (name === "percentage" || name === "amount") {
                const percentage = parseFloat(newFormData.percentage);
                const amount = parseFloat(newFormData.amount);

                // Calculate commission if both percentage and amount are valid
                if (!isNaN(percentage) && !isNaN(amount) && amount > 0 && percentage > 0) {
                    newFormData.commission = ((percentage / 100) * amount).toFixed(2); // Round to 2 decimal places
                } else {
                    newFormData.commission = ""; // Clear commission if inputs are invalid
                }
            }

            return newFormData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create an object for your request payload
        const data = {
            customerID: formData.customerID,
            companyName: formData.companyName,
            customerName: formData.customerName,
            phoneNumber: formData.phoneNumber,
            investmentDate: formData.investmentDate,
            amount: formData.amount,
            percentage: formData.percentage,
            commission: formData.commission,
        };

        try {
            const response = await axios.post('https://www.upholic.in/Upholic_Api/third_party_data.php', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Handle successful submission (response.data is assumed to be the response from the backend)
            // console.log(response.data);
            alert('Data saved successfully!');
            
            // Reset the form data if needed
            setFormData({
                customerID: '',
                companyName: '',
                customerName: '',
                phoneNumber: '',
                investmentDate: '',
                amount: '',
                percentage: '',
                commission: ''
            });

            // Optionally close the accordion after submission
            setAccordionOpen(false);

        } catch (error) {
            console.error('Error during form submission:', error);
            alert('There was an error submitting the data.');
        }
    };

    return (
        <>
            <Admin_sidebar_links />
            <div className="viewall-container">
                <div className="section">
                    <div className="section-title" onClick={toggleAccordion}>
                        Third Party Data Entry
                    </div>
                    {accordionOpen && (
                        <>
                            <form onSubmit={handleSubmit} className="Pms_insert_user_kyc-form">
                                <div className="Pms_insert_user_kyc-row">
                                    <label>Customer ID:</label>
                                    <input
                                        type="text"
                                        name="customerID"
                                        value={formData.customerID}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Company Name:</label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Customer Name:</label>
                                    <input
                                        type="text"
                                        name="customerName"
                                        value={formData.customerName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Phone Number:</label>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        maxLength="10"
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Date of Investment:</label>
                                    <input
                                        type="date"
                                        name="investmentDate"
                                        value={formData.investmentDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Amount:</label>
                                    <input
                                        type="text"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Percentage:</label>
                                    <input
                                        type="text"
                                        name="percentage"
                                        value={formData.percentage}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="Pms_insert_user_kyc-row">
                                    <label>Commission:</label>
                                    <input
                                        type="text"
                                        name="commission"
                                        value={formData.commission}
                                        onChange={handleChange}
                                        required
                                        readOnly
                                    />
                                </div>

                                <div className="investment-row">
                                    <button type="submit" className="edit-button">
                                        {isExistingData ? "Update" : "Insert"}
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Third_Party_Data_Entry;
