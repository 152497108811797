import React from "react";
import Navbar from "../navbar/Navbar";
import Carousel from "../carousel/Carousel";
import About from "../about/About";
import Fact from "../fact/Fact";
import Features from "../features/Features";
import Our_Services from "../our-services/Our_Services";
import Call_Back from "../call-back/Call_Back";
import Footer from "../footer/Footer";
import Copyright from "../copyright/Copyright";
import Info_Carousel from "../info-carousel/Info_Carousel";

function Home() {
  return (
    <>
      <Navbar />
      <Carousel />
      <About />
      <Features />
      <Info_Carousel/>
      {/* <Fact /> */}
      <Our_Services />
      <Call_Back />
      <Footer />
      <Copyright />
    </>
  );
}

export default Home;
