import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Call_Back.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import call_back_img from "../../assets/images/callback_img.jpg";

function Call_Back() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: false, // Allow animation to repeat every time on scroll
      mirror: true, // Repeat animation when scrolling up
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [buttonText, setButtonText] = useState("Submit Now");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Submitting...");
    axios
      .post("https://www.upholic.in/Upholic_Api/requestcall.php", formData)
      .then(() => {
        setButtonText("Submitted!");
        setFormData({ name: "", email: "", mobile: "", message: "" });
      })
      .catch(() => setButtonText("Submit Now"));
  };

  return (
    <>
      <div className="call-back-wrapper">
        <div className="call-back-container">
          <div className="call-back-header" data-aos="fade-up">
            <h1 className="call-back-heading">Book free appointment!</h1>
            <p className="call-back-paragraph">Request a callback, and we'll be in touch shortly.</p>
          </div>

          <div className="callback-content-row">
            <div className="callback-image-container" data-aos="fade-right">
              <img
                src={call_back_img}
                alt="Callback"
                className="callback-image"
              />
            </div>

            <form
              onSubmit={handleSubmit}
              className="call-back-form"
              data-aos="fade-up"
            >
              <div className="callback-form-group" data-aos="fade-up">
                <label className="callback-label">Name</label>
                <input
                className="callback-input"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className="callback-form-group"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <label className="callback-label">Email</label>
                <input
                  className="callback-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className="callback-form-group"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <label className="callback-label">Mobile</label>
                <input
                  className="callback-input"
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className="callback-form-group"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <label className="callback-label">Message</label>
                <textarea
                  className="callback-massage"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="callback-submit-button"
                data-aos="zoom-in"
              >
                {buttonText}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Call_Back;
