import React, { useEffect } from "react";
import "./Life_Insurance_Login.css";
import { Link } from "react-router-dom";
import Life_Insurance from "../../assets/images/insurancehero.avif";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

function Life_Insurance_Login() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Animation occurs only once
    });
  }, []);

  return (
    <div className="life-insurance-try-container">
      <div
        className="life-insurance-try-content"
        data-aos="fade-up" // Enable AOS animation
      >
        <h3 className="life-insurance-try-subtitle" data-aos="fade-up">
          Insurances
        </h3>
        <h1 className="life-insurance-try-title" data-aos="fade-up">
          Secure Your Future with Comprehensive Insurance
        </h1>
        <p className="life-insurance-try-description" data-aos="fade-up">
          Making insurance decisions can be overwhelming. Let us simplify the
          process and help you choose the best coverage for your needs.
        </p>
        <Link to="/Signup">
          <button className="life-insurance-try-button" data-aos="fade-up">
            Try it now
          </button>
        </Link>
      </div>
      <div
        className="life-insurance-try-image-container"
        data-aos="fade-up" // Enable AOS animation
      >
        <img
          src={Life_Insurance}
          alt="Insurance illustration"
          className="life-insurance-try-image"
        />
      </div>
    </div>
  );
}

export default Life_Insurance_Login;